import status from 'constants/status'

export const appStatus = {
  INITIALIZING: {
    id: 'INITIALIZING',
    title: 'Initializing',
    message: 'Please wait while the application initializes'
  },
  READY: {
    id: 'READY',
    title: 'Ready',
    message: 'Application is ready'
  },
  LOADING: {
    id: 'LOADING',
    title: 'Loading',
    message: 'Waiting for a response from AEM'
  },
  AUTHENTICATING: {
    id: 'AUTHENTICATING',
    title: 'Authenticating',
    message: 'Contacting AEM to get permission to use the DNB Content Manager'
  },
  NOT_AUTHORIZED: {
    id: 'NOT_AUTHORIZED',
    ...status[403]
  },
  ERROR: {
    id: 'ERROR',
    ...status[500]
  }
} as const

export type AppStatusType = typeof appStatus

export default {
  APP_CHANGE_STATUS: 'App/CHANGE_STATUS',
  APP_FULLSCREEN_PREVIEW_TOGGLE: 'App/FULLSCREEN_PREVIEW_TOGGLE',
  APP_SET_IS_READONLY_MODE: 'App/SET_IS_READONLY_MODE',
  APP_SET_SITE_URL: 'App/SET_SITE_URL'
} as const
