import constants, { appStatus } from 'redux/constants/AppReducerConstants'
import Reducer, { Cases } from 'redux/reducer'

import { AppState } from './types'

const initialState: AppState = {
  status: appStatus.INITIALIZING,
  siteUrl: '',
  isFullscreenPreviewMode: false,
  isReadOnlyMode: false
}

const cases: Cases<AppState> = {
  [constants.APP_CHANGE_STATUS]: (state, status) => {
    state.status = status
  },
  [constants.APP_SET_SITE_URL]: (state, siteUrl) => {
    state.siteUrl = siteUrl
  },
  [constants.APP_SET_IS_READONLY_MODE]: (state, isReadOnlyMode) => {
    state.isReadOnlyMode = isReadOnlyMode
    if (isReadOnlyMode) {
      state.isFullscreenPreviewMode = true
    }
  },
  [constants.APP_FULLSCREEN_PREVIEW_TOGGLE]: (state) => {
    state.isFullscreenPreviewMode = !state.isFullscreenPreviewMode
  }
}

export default Reducer(cases, initialState)
